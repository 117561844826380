  @font-face {
    font-family: 'icomoon';
    src:  url('assets/fonts/icomoon.eot?i6a7n7');
    src:  url('assets/fonts/icomoon.eot?i6a7n7#iefix') format('embedded-opentype'),
      url('assets/fonts/icomoon.ttf?i6a7n7') format('truetype'),
      url('assets/fonts/icomoon.woff?i6a7n7') format('woff'),
      url('assets/fonts/icomoon.svg?i6a7n7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  * Styles for Scrollbar in Chrome */
  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-smartphone-call:before {
    content: "\e903";
    color: #595959;
  }
  .icon-working-tools:before {
    content: "\e901";
    color: #a90112;
  }
  .icon-icon:before {
    content: "\e902";
  }
  .icon-account_circle:before {
    content: "\e900";
  }
  .icon-cart:before {
    content: "\e93a";
  }
  .icon-phone:before {
    content: "\e942";
  }
  .icon-clock:before {
    content: "\e94e";
  }
  .icon-facebook:before {
    content: "\ea90";
  }
  .icon-instagram:before {
    content: "\ea92";
  }
  .icon-whatsapp:before {
    content: "\ea93";
  }
  .icon-youtube:before {
    content: "\ea9d";
  }
  .icon-google:before {
    content: "\ea88";
  }
  .icon-target:before {
    content: "\e9b3";
  }
  .icon-kcarrito:before {
    content: "\e905";
    color: #f00;
  }
  .icon-kcatalogo:before {
    content: "\e906";
    color: #f00;
  }
  .icon-kcuenta:before {
    content: "\e907";
    color: #f00;
  }
  .icon-klogin:before {
    content: "\e908";
    color: #f00;
  }
  .icon-kpedidos:before {
    content: "\e909";
    color: #f00;
  }
  .icon-ksucursales:before {
    content: "\e90a";
    color: #f00;
  }
  .icon-search .path1:before {
    content: "\e90b";
    color: #fbbb00;
  }
  .icon-search .path2:before {
    content: "\e90c";
    color: #518ef8;
    margin-left: -1em;
  }
  .icon-search .path3:before {
    content: "\e90d";
    color: #28b446;
    margin-left: -1em;
  }
  .icon-search .path4:before {
    content: "\e90e";
    color: #f14336;
    margin-left: -1em;
  }
  .icon-kaizen-ingresar:before {
    content: "\e911";
    color: #f00;
  }
  .icon-kaizen-carrito:before {
    content: "\e90f";
    color: #f00;
  }
  .icon-kaizen-catalogo:before {
    content: "\e910";
    color: #f00;
  }
  .icon-kaizen-micuenta:before {
    content: "\e912";
    color: #f00;
  }
  .icon-kaizen-micuenta-mobile:before {
    content: "\e912";
    color: #ffffff;
}
  .icon-kaizen-pedido:before {
    content: "\e913";
    color: #f00;
  }
  .icon-kaizen-sucursales:before {
    content: "\e914";
    color: #f00;
  }
  .icon-icono-ingresar:before {
    content: "\e915";
    color: #f00;
  }
  .icon-icono-repuestos:before {
    content: "\e916";
    color: #f00;
  }
  .icon-icono-taller:before {
    content: "\e917";
    color: #f00;
  }
  .icon-kaizen-help:before {
    content: "\e918";
    color: #f00;
  }
  .icon-repuestos1:before {
    content: "\e919";
    color: #f00;
  }
  .icon-tachometer-red:before {
    content: "\e91a";
    color: #f00;
  }
  .icon-tachometer:before {
    content: "\e91b";
  }
  .icon-offers-kaizen .path1:before {
    content: "\e91e";
    color: #f00;
  }
  .icon-offers-kaizen .path2:before {
    content: "\e91f";
    color: #fff;
    margin-left: -1em;
  }
 
  /* -------------- Estilos personalizados -------------- */
  body{
    overflow-x: hidden;
  }
  .color-kaizen {
    color: #ff0000;
  }
  .back-kaizen {
    background-color: #ff0000;
  }
  .grecaptcha-badge {
    display: none !important;
  }
  .submenu_itemCart li:hover {
    color: #ffffff !important;
  }
  .submenu_itemCart li a:hover {
    color: #ffffff !important;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: #ff0000 !important;
  }
  .ant-steps-item-process .ant-steps-item-icon {
      border-color: #ff0000 !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #ff0000 !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #ff0000 !important;
  }
  app-home {
    display: flow-root;
    background-color: #d6d6d647 !important;
  }
  .ant-tabs-nav .ant-tabs-tab:active {
    color: #ff0000 !important;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0000 !important;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0000 !important;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #ff0000 !important;
  }
  .title-section {
    font-size: 24px;
  }
  .subtitle {
    color: #333333;
    text-align: center;
    text-transform: uppercase;
    height: 40px;
  }
  .marca {
    color: #888888;
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-top: -10px !important;
  }
  .ant-switch-checked {
    background-color: #ff0000 !important;
  }
  .ant-card-body {
    padding: 10px 20px !important;
  }
  /*
    ### Pg Marcas
  */
  .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #ff0000;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff0000 !important;
    border-color: #ff0000 !important;
  }
  
  
  .ant-collapse-header {
    font-weight: 600;
    color: #414141 !important;
  }



  .list-view .ant-list-header {
    background: red;
    text-align: center;
    color: #ffffff;
    font-size: 15pt;
    font-weight: 700;
}

.confirm-pago {
  background: red;
  display: block;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding: 2%;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  margin-top: 3%;
  margin-bottom: 4%;
}

.fieldDate * {
  width: 100% !important;
}

.fieldDate {
  width: 100% !important;
}

.ant-calendar-picker-icon {
  text-align: right !important;
  float: right !important;
  width: auto !important;
  position: absolute !important;
  right: -50%;
}

.menu-foot .ant-menu-horizontal>.ant-menu-item-active, .ant-menu-horizontal>.ant-menu-item-open, .ant-menu-horizontal>.ant-menu-item-selected, .ant-menu-horizontal>.ant-menu-item:hover, .ant-menu-horizontal>.ant-menu-submenu-active, .ant-menu-horizontal>.ant-menu-submenu-open, .ant-menu-horizontal>.ant-menu-submenu-selected, .ant-menu-horizontal>.ant-menu-submenu:hover {
  border-bottom: 1px solid #ffffff8c !important;
}

.table-compatibilidad .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-scroll .ant-table-body {
    min-height: auto !important;
}

/* ---------------- Repuestos ----------------*/

.repuesto-galeria .owl-next {
  position: absolute !important;
  top: 33% !important;
  right: 2% !important;
  z-index: 100 !important;
}
.repuesto-galeria .owl-prev {
  position: absolute !important;
  top: 33% !important;
  left: 1% !important;
  z-index: 100 !important;
}
.table-compatibilidad .ant-pagination {
  display: none !important;
}
.repuesto-galeria .owl-carousel .owl-item {
  width: 524px !important;
}

/* TRACKING */
.ant-progress-bg {
  background-color: #bfbfbf;
}
.ant-progress-success-bg {
  background-color: #ff0000;
}
.label-quest label {
  font-size: 13pt;
  font-weight: 600;
  color: #66696b;
}
.opc-quest {
  padding-left: 5%;
  margin-top: 1%;
}

/* SERVICIO TECNICO */
.galeria-ss .owl-next {
  position: absolute !important;
  top: 33% !important;
  right: 2% !important;
  z-index: 100 !important;
}
.galeria-ss .owl-prev {
  position: absolute !important;
  top: 33% !important;
  left: 1% !important;
  z-index: 100 !important;
}

/* VIDEO */
.video-swiper .owl-item {
  padding-right: 10px !important;
}
.video-swiper .owl-next {
  position: absolute !important;
  top: 25% !important;
  right:  -1% !important;
  z-index: 100 !important;
  background: #ff0000 !important;
  border-radius: 50% !important;
  padding: 9px 10px 9px 9px !important;

}
.video-swiper .owl-prev {
  position: absolute !important;
  top: 25% !important;
  left:  -1% !important;
  z-index: 100 !important;
  background: #ff0000 !important;
  border-radius: 50% !important;
  padding: 9px 9px 9px 10px !important;

}
.nav-right_home img {
  width: 20px !important;
}
.nav-left_home img {
  width: 20px !important;
}

.table-detallecompatibilidad nz-pagination {
  display: none;
}
/* Fichas tecnicas */
.cantidad-select nz-input-number {
  background: transparent;
}
.cantidad-select .ant-input-number-handler-wrap {
  opacity: 1 !important;
  background: #d9d9d9;
}

.modal-sucursales .ant-modal {
  width: 60% !important;
}
.popup-principal .ant-modal {
  width: 40% !important;
}

.filter-select nz-select-top-control {
  border-radius: 25px !important;
  padding-top: 5px !important;
  height: 40px !important;
}
.direction-select nz-select-top-control {
  border-radius: 25px !important;
  padding-top: 5px !important;
  height: 40px !important;
}
/* Genericos */
::after {
  display: none !important;
}

.ant-spin-dot-item {
  background-color: red;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: red !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: red !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid red !important;
}

.mobile {
  display: none;
}
.desktop {
  display: block;
}
.ant-pagination-item-active {
  border-color: red !important;
}
.ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a {
  color: red !important;
}
.ant-pagination-item-active a, .ant-pagination-item:focus-visible a, .ant-pagination-item:hover a {
  color: red !important;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("assets/images/servicio-tecnico/btn_der.svg") !important;
  right: 10px;
  left: auto;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("assets/images/servicio-tecnico/btn_izq.svg") !important;
  left: 10px;
  right: auto;
}
/**/
.estilov2 nz-select-top-control {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 1px solid #000000 !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  float: left;
}
.ant-progress-text {
  display: none;
}
nz-progress {
  display: none;
}
.nosuc {
  background-color: #ff000094;
}
.successnot {
  background-color: rgba(0, 128, 0, 0.26);
}
.menu .ant-divider-vertical {
  border-left: 1px solid #ffffff !important;
}
@media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)  {
    app-home {
      background-color: #d6d6d647 !important;
    }
  }
  /*
    ### Ipad portrait
  */
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : portrait) {
    .content-header {
      padding-left: 10%;
    }
    .content-menu {
      margin-left: 8%;
      width: 100%;
    }
  }
  /*
    ### Ipad landscape
  */
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : landscape) {
    .content-menu {
      margin-left: 8%;
      width: 80%;
    }   
    .kaizen-header {
      width: 100%;
      margin-left: 0px;
    }
    .nav-menu.nav-menu_header {
      width: 100%;
      font-size: 9pt;
    }
    .content-header-right {
      width: 14% !important;
    }
    .submenu {
      width: 40% !important;
      right: 0.2% !important;
    }
  }

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
*/
@media (min-width: 320px) and (max-width: 480px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
    .ant-drawer-content {
        background: #ffffff;
        color: #ce0000;
    }
    .kaizen-menu_mobile li a {
        color: #ce0000 !important;
        font-weight: 500;  
    }
    .ant-drawer-body ul li a {
        color: #ce0000;
        font-size: 12pt;
    }
    .ant-drawer-body ul li {
      list-style: none;
      padding: 4% 0px;
      /*border-bottom: 1px solid #ce0000;*/
    }
    .kaizen-submenu_mobile {
      background: #ffffff !important;
    }
    .ant-menu::after, .ant-menu::before {
      content: none !important;
    }
    .iconuser-mobile {
      font-size: 25pt !important;
    }
    .date-item_select div div .ant-select-selection-selected-value {
        font-size: 8pt !important;
    }
    .ant-drawer-header {
      background: #ce0000 !important;
    }
    .ant-drawer-title {
      color: #ffffff !important;
    }
    .repuesto-galeria .owl-carousel .owl-item {
      width: 344px !important;
    }
    .modal-sucursales .ant-modal {
      width: 80% !important;
  }
  .popup-principal .ant-modal {
    width: 80% !important;
  }
  .cantidad-select nz-select nz-select-top-control {
    border-radius: 25px !important;
    background: #f0f0f0 !important;
  }
  
  .table-compatibilidad table {
    table-layout: inherit !important;
    width: 100%;
  }
  .table-compatibilidad nz-spin nz-table-inner-scroll table {
      table-layout: initial !important;
  }
  .table-compatibilidad nz-spin nz-table-inner-scroll {
    overflow-x: scroll;
    width: 320px;
  }
}

 /* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1.5) {
  .kaizen-header {
      margin-left: 0px !important;
      width: 100% !important;
  }
  .content-header-right {
    float: none !important;
    margin-top: 0.5% !important;
    display: block;
  }
  .submenu {
    width: 40% !important;
    right: 0.4% !important;
  }
}

/* Landscape */
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 1.5) {

}
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import '~swiper/dist/css/swiper.min.css';